import { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { member_init_with_token_api } from "src/DAL/ContentSetting/ContentSetting";
import ReactGA from "react-ga4";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isLoadingApp, setIsLoadingApp] = useState(false);
  const location = useLocation();
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleAdminTimeZone,
    handleUserInfo,
    handleNavbarList,
    handleStripeKey,
    handleNotifications,
    handleProjectInfo,
  } = useContentSetting();

  function test(words) {
    var n = words.split("=");
    return n[n.length - 1];
  }
  let mobileView = test(location.search);

  const handleGetContent = async () => {
    setIsLoadingApp(true);
    const result = await member_init_with_token_api();
    if (result.code === 200) {
      let notification_settings = result.member.notification_settings;
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleContentSettingData({});
        handleNavbarList({});
        handleUserInfo({});
        handleAdminTimeZone("");
        handleNotifications({});
        return;
      }
      if (result.content_setting.client_general_setting?.analytic_google_code) {
        ReactGA.initialize(
          result.content_setting.client_general_setting.analytic_google_code,
          { debug: true }
        );
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result.content_setting.client_dashboard_setting)
      );
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.content_setting.wheel_of_life_setting)
      );
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(`navbar_list`, JSON.stringify(result.menus));
      localStorage.setItem(
        `jsCode`,
        JSON.stringify(result.client_portal_js_code)
      );
      localStorage.setItem(
        `cssCode`,
        JSON.stringify(result.client_portal_css_code)
      );
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.member_consultant)
      );
      handleProjectInfo(result.content_setting.client_general_setting);
      handleAdminTimeZone(result.time_zone);
      handleNavbarList(result.menus);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );
      handleUserInfo(result.member);

      if (notification_settings) {
        handleNotifications(notification_settings);
      }

      if (result.site_setting.stripe_mode == "sandBox") {
        handleStripeKey(result.site_setting.sandBox_publish_key);
      } else {
        handleStripeKey(result.site_setting.live_publish_key);
      }
      setIsLoadingApp(false);
    } else {
      setIsLoadingApp(false);
    }
  };

  useEffect(() => {
    handleGetContent();
  }, []);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${location.pathname}`,
      title: `${location.pathname}`,
    });
  }, [location]);

  if (isLoadingApp) {
    return (
      <>
        <CircularProgress className="centered" color="primary" />
      </>
    );
  }

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  return (
    <RootStyle>
      {mobileView == "mobile" ? (
        ""
      ) : (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        </>
      )}

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
